<template>
  <div
    id="contact-form"
    :class="{ compact, submitted: formSubmitted }"
  >
    <template v-if="!formSubmitted">
      <div class="heading">
        <divider />
        <!-- eslint-disable vue/no-v-html -->
        <h1
          class="contact-header"
          v-html="header"
        />
        <p
          class="contact-subheader"
          v-html="subheader"
        />
        <template v-if="showContacts">
          <span
            v-for="(contact, index) in contacts"
            :key="index"
            class="contact-info"
          >
            <img
              class="contact-icon"
              :src="contact.icon"
              :alt="contact.title"
            >
            <a
              v-if="contact.href"
              :href="contact.href"
              target="_blank"
              class="contact-info"
            >
              {{ contact.title }}
            </a>
            <template v-else>
              {{ contact.title }}
            </template>
          </span>
        </template>
      </div>
      <el-form
        ref="contactForm"
        :model="form"
        :rules="rules"
        class="form"
      >
        <div class="contact-fields">
          <el-form-item
            v-for="(field,index) in fields"
            :key="index"
            :prop="field.name"
            class="contact-field"
          >
            <label
              class="input-label"
              :for="field"
            >
              {{ $t(`fields.${field.name}`) }}
              {{ field.required ? ' *' : '' }}
            </label>
            <el-input
              :id="field.name"
              v-model="form[field.name]"
              :name="field.name"
              :type="field.type"
              class="input-field"
            />
          </el-form-item>
          <el-form-item
            v-if="!compact && !showInquiry"
            class="contact-field"
            prop="country"
          >
            <!--suppress XmlInvalidId -->
            <label
              class="input-label"
              for="country"
            >
              {{ $t('fields.country') }}
            </label>
            <el-select
              id="country"
              v-model="form.country"
              name="country"
              placeholder=""
            >
              <el-option
                v-for="country in countryList"
                :key="country.value"
                :value="country.value"
                :label="country.label"
              />
            </el-select>
          </el-form-item>
        </div>
        <el-form-item
          v-if="!compact && !showInquiry"
          prop="message"
        >
          <el-input
            v-model="form.message"
            type="textarea"
            :placeholder="$t(messagePlaceholder)"
            :autosize="{ minRows: 4 }"
            class="contact-message"
          />
        </el-form-item>
        <inquiry-form
          v-if="showInquiry"
          :services.sync="form.services"
          :topics.sync="form.topics"
          :message.sync="form.message"
        />
        <div class="buttons-block">
          <el-button
            class="button reset-button"
            @click="resetClick"
          >
            {{ $t('button.reset') }}
          </el-button>
          <el-button
            :loading="formSubmitting"
            class="button submit-button"
            @click="submitClick"
          >
            {{ $t(buttonText) }}
          </el-button>
        </div>
      </el-form>
    </template>
    <div
      v-else
      class="status-container"
    >
      <img
        :src="statusIcon"
        :alt="statusHeader"
        class="status-icon"
      >
      <div class="status-header">
        {{ statusHeader }}
      </div>
      <div class="status-subheader">
        {{ statusSubheader }}
      </div>
      <button
        v-if="!compact"
        class="btn-action back-button"
        @click="onBackClick"
      >
        {{ $t('contactForm.backButton') }}
      </button>
    </div>
  </div>
</template>

<script>
import Divider from '@/components/Divider/Index.vue';
import countries from '@/libs/countries';
import Envelope from '@/assets/images/icons/envelope-blue.svg';
import Pin from '@/assets/images/icons/pin-blue.svg';
import ContactSuccess from '@/assets/images/icons/contact-success.svg';
import ContactError from '@/assets/images/icons/contact-error.svg';
import InquiryForm from '@/views/components/ContactForm/InquiryForm.vue';

const initialFormData = () => ({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  companyName: '',
  jobTitle: '',
  country: null,
  message: '',
  services: [],
  topics: [],
});

export default {
  name: 'ContactForm',
  components: { InquiryForm, Divider },
  props: {
    header: {
      type: String,
      default: '',
    },
    subheader: {
      type: String,
      default: '',
    },
    showContacts: {
      type: Boolean,
      default: false,
    },
    showInquiry: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'button.submit',
    },
    messagePlaceholder: {
      type: String,
      default: 'contactForm.messagePlaceholder',
    },
    successHeader: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    formSubmitting: false,
    formSubmitted: false,
    submitSuccess: false,
    form: initialFormData(),
  }),
  computed: {
    fields() {
      return [
        {
          name: 'firstName',
          type: 'text',
          required: true,
        },
        {
          name: 'lastName',
          type: 'text',
          required: true,
        },
        {
          name: 'email',
          type: 'email',
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(this.$t('validation.required')));
            }
            const valid = this.$utils.validateEmail(value);
            if (!valid) {
              callback(new Error(this.$t('validation.email')));
            }
            callback();
          },
          trigger: 'blur',
        },
        !this.compact && {
          name: 'phoneNumber',
          type: 'tel',
        },
        {
          name: 'companyName',
          type: 'text',
          required: true,
        },
        !this.compact && {
          name: 'jobTitle',
          type: 'text',
          required: true,
        },
      ]
        .filter((_) => _);
    },
    rules() {
      const rules = {
        message: [{
          required: true,
          message: this.$t('validation.required'),
          trigger: 'change',
        }],
      };
      this.fields.forEach((field) => {
        rules[field.name] = [{
          required: field.required,
          validator: field.validator,
          message: field.required && !field.validator ? this.$t('validation.required') : undefined,
          trigger: field.trigger || 'change',
        }];
      });
      return rules;
    },
    contacts() {
      return [
        {
          icon: Pin,
          title: this.$consts.contact.address,
          href: window.navigator.appVersion.indexOf('Mac') === -1 ? 'https://www.google.com/maps/place/Al+RawdahCapital+Centre+-+Abu+Dhabi/@24.4190562,54.4323001,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5e4275f810997d:0x68098e2cb04d781c!8m2!3d24.4190562!4d54.4344888' : 'maps://?q=24.418687576575678, 54.43436306635606',
        },
        {
          icon: Envelope,
          title: this.$consts.contact.email,
          href: this.$consts.contact.mailto,
        },
      ];
    },
    countryList() {
      return countries
        .map((country) => ({
          value: country.code,
          label: country.name,
        }));
    },
    statusIcon() {
      return this.submitSuccess ? ContactSuccess : ContactError;
    },
    statusHeader() {
      return this.successHeader ? this.$t(this.successHeader) : this.$t(`contactForm.${this.submitSuccess ? 'success' : 'error'}.header`);
    },
    statusSubheader() {
      return this.$t(`contactForm.${this.submitSuccess ? 'success' : 'error'}.subheader`);
    },
  },
  methods: {
    resetClick() {
      const formData = initialFormData();
      Object.keys(formData).forEach((key) => {
        this.form[key] = formData[key];
      });
      this.$nextTick(() => this.$refs.contactForm.clearValidate());
    },
    async submitClick() {
      const valid = await this.$refs.contactForm.validate();
      if (!valid) {
        return;
      }
      this.formSubmitting = true;
      try {
        await this.$api.contact.postInquiry({
          firstname: this.form.firstName,
          lastname: this.form.lastName,
          email: this.form.email,
          phone: this.form.phoneNumber,
          description: this.form.message,
          company: this.form.companyName,
          job: this.form.jobTitle,
          country: this.form.country,
          source_track: this.source,
          services: this.form.services,
          topics: this.form.topics,
        });
        this.submitSuccess = true;
      } finally {
        this.formSubmitting = false;
        this.formSubmitted = true;
      }
    },
    onBackClick() {
      this.formSubmitted = false;
    },
  },
};
</script>

<style lang="scss">
  .el-select-dropdown__item {
    font-family: 'Montserrat', sans-serif;
  }
</style>

<style lang="scss" scoped>
  #contact-form {
    background:
      url('~@/assets/images/background/contact-cloud.svg') top left no-repeat,
      url('~@/assets/images/background/contact-cloud-2.svg') top right no-repeat,
      $background-secondary;
    padding: 0.32rem 0.86rem 0.6rem;

    &.submitted {
      background:
        url('~@/assets/images/background/contact-cloud.svg') top left no-repeat,
        $background-secondary;
    }

    .contact-header {
      margin-top: 0.22rem;
      font-weight: 800;
      font-size: 0.46rem;
      line-height: 0.63rem;

      /deep/ small {
        font-weight: 400;
        font-size: inherit;
        line-height: inherit;
      }
    }

    .contact-subheader {
      margin-top: 0.12rem;
      color: $text-tertiary;
      font-weight: 400;
      font-size: 0.16rem;
      line-height: 0.29rem
    }

    .contact-info {
      display: inline-block;
      margin-top: 0.16rem;
      margin-right: 0.25rem;
      color: $text-blue;

      .contact-icon {
        margin-right: 0.16rem;
        vertical-align: middle;
      }
    }

    .contact-fields {
      display: flex;
      flex-wrap: wrap;
      margin: -0.16rem;
      margin-top: 0.36rem;

      .contact-field {
        width: 33.33%;
        padding: 0.16rem;
        margin-bottom: 0.1rem;
        position: relative;

        .input-label {
          position: absolute;
          z-index: 1;
          top: 0.12rem;
          left: 0.2rem;
          font-size: 0.12rem;
          line-height: 0.15rem;
          opacity: 0.5;
        }

        /deep/ .el-select {
          width: 100%;
        }

        /deep/ .el-input__inner {
          @include input-field;
          height: 0.64rem;
          padding: 0.32rem 0.2rem 0.12rem;
        }

        /deep/ .el-form-item {
          margin-bottom: 0.1rem;
        }
      }
    }

    .contact-message {
      margin-top: 0.32rem;

      /deep/ .el-textarea__inner {
        @include input-field;
        padding: 0.2rem;
        font-size: 0.16rem;
        line-height: 0.2rem;
        font-family: 'Montserrat', sans-serif;

        &::placeholder {
          color: rgba($text-primary, 0.5);
        }
      }
    }

    .buttons-block {
      margin-top: 0.6rem;
      text-align: right;

      .button {
        padding: .19rem .7rem;
        border-radius: 0.06rem;
      }

      .submit-button {
        margin-left: 0.16rem;
        border: none;
        color: white;
        background: $background-primary;
      }
    }

    .reset-button,
    .back-button {
      border: $button-border;
      color: #455a64;
      font-weight: 400;
      background: transparent;
    }

    .status-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1.5rem auto;

      .status-header {
        margin-top: 0.32rem;
        font-weight: 800;
        font-size: 0.32rem;
        line-height: 0.43rem;
        letter-spacing: 0.01em;
      }

      .status-subheader {
        margin-top: 0.16rem;
        color: $text-tertiary;
        font-size: 0.16rem;
        line-height: 0.29rem;
      }

      .back-button {
        margin-top: 0.6rem;
        padding: 0.16rem 0.66rem;
      }
    }

    &.compact {
      $form-width: 3.2rem;
      $column-spacing: 0.76rem;

      display: flex;
      justify-content: space-between;
      background: $background-secondary;
      padding-top: 0.6rem;

      .heading {
        width: $form-width;
      }

      .form {
        width: calc(100% - #{$form-width} - #{$column-spacing});
      }

      .contact-fields {
        margin-top: -0.16rem;

        .contact-field {
          width: 50%;
        }
      }

      .status-container {
        margin: 0.6rem auto;
      }
    }

    &, &.compact {
      @include tablet-and-less {
        flex-direction: column;
        padding-left: 0.3rem;
        padding-right: 0.3rem;

        .heading {
          width: 100%;
        }

        .form {
          margin-top: 0.16rem;
          width: 100%;

          .contact-fields {
            .contact-field {
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
