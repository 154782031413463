<template>
  <div id="inquiry-form">
    <div class="form-heading">
      <div class="icon">
        <img
          :src="require('@/assets/images/icons/inquiry.svg')"
          alt="Inquiry"
        >
      </div>
      <div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="header"
          v-html="$t('inquiryForm.header')"
        />
        <div class="subheader">
          {{ $t('inquiryForm.subheader') }}
        </div>
      </div>
    </div>
    <div class="form-fields">
      <el-tree
        ref="services"
        :data="servicesList"
        :expand-on-click-node="false"
        check-on-click-node
        node-key="id"
        default-expand-all
        show-checkbox
        class="service-tree"
        @check-change="handleCheckChange"
      />
      <div class="section-heading">
        {{ $t('inquiryForm.topicsHeader') }}
      </div>
      <div class="topics">
        <button
          v-for="topic in topicsList"
          :key="topic"
          :class="{ selected: topics.indexOf(topic) !== -1 }"
          type="button"
          class="topic-button"
          @click="() => onTopicClick( topic)"
        >
          {{ $t(`inquiryForm.topics['${topic}']`) }}
        </button>
      </div>
      <div class="section-heading">
        {{ $t('inquiryForm.messageHeader') }}
      </div>
      <el-input
        :value="message"
        type="textarea"
        :placeholder="$t('contactForm.messagePlaceholder')"
        :autosize="{ minRows: 4 }"
        class="inquiry-message"
        @input="onMessageChange"
      />
    </div>
  </div>
</template>

<script>
import AIServices from '@/router/AIServices';

export default {
  name: 'InquiryForm',
  props: {
    services: {
      type: Array,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    servicesList() {
      return AIServices.children.map((mainRoute) => ({
        id: mainRoute.name,
        label: this.$t(`button['${mainRoute.meta.navName}']`),
        children: mainRoute.children && mainRoute.children
          .slice(0, -1)
          .filter((el) => !el.meta.hideInNav)
          .map((subRoute) => ({
            id: subRoute.name,
            label: this.$t(`button['${subRoute.meta.navName}']`),
          })),
      }));
    },
    topicsList() {
      return [
        'pricing-and-billing',
        'technology-questions',
        'marketing-campaign',
        'request-demo',
        'business-partnership',
        'others',
      ];
    },
  },
  methods: {
    handleCheckChange() {
      this.$emit('update:services', this.$refs.services.getCheckedKeys());
    },
    onMessageChange(text) {
      this.$emit('update:message', text);
    },
    onTopicClick(topic) {
      const newTopics = this.topics.indexOf(topic) === -1
        ? [...this.topics, topic]
        : this.topics.filter((el) => el !== topic);
      this.$emit('update:topics', newTopics);
    },
  },
};
</script>

<style lang="scss" scoped>
  #inquiry-form {
    margin-top: 0.5rem;

    .form-heading {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 0.16rem;
        padding: 0.11rem;
        border-radius: 50%;
        box-shadow: $icon-shadow;
        background: $background-white;
      }

      .header {
        font-weight: 800;
        font-size: 0.28rem;
        line-height: 0.34rem;

        /deep/ small {
          font-weight: 400;
          font-size: inherit;
          line-height: inherit;
        }
      }

      .subheader {
        margin-top: 0.02rem;
        color: $text-tertiary;
        font-size: 0.16rem;
        line-height: 0.29rem;
      }
    }

    .form-fields {
      margin-top: 0.16rem;
      padding: 0.32rem;
      border-radius: 0.06rem;
      background-color: $background-white;
    }

    .service-tree {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 0.32rem;
      margin-bottom: 0.22rem;
      border-bottom: 1px solid $color-gray;
      color: rgba($text-primary, 0.4);

      & > /deep/ .el-tree-node {
        width: 13.5%;
        min-width: 2rem;
        height: unset;
        white-space: unset;
        margin-right: 0.08rem;

        & > .el-tree-node__content {
          height: unset;

          .el-checkbox__inner {
            border-color: $icon-primary;
          }

          & > .el-tree-node__label {
            max-width: 1.5rem;
            font-weight: 600;
            font-size: 0.16rem;
            line-height: 0.22rem;
          }
        }
      }

      /deep/ .el-tree-node.is-checked {
        color: $text-primary;
      }

      /deep/ .el-tree-node:focus > .el-tree-node__content {
        background: none;
        color: $text-primary;
      }

      /deep/ .el-tree-node__label {
        font-size: 0.12rem;
        line-height: 0.18rem;
      }

      /deep/ .el-tree-node__expand-icon {
        display: none;
      }

      /deep/ .el-tree-node__content {
        align-items: flex-start;
        padding-left: 0 !important; // Overwriting inline styles from elementUI
        height: 0.24rem;
        margin-bottom: 0.14rem;

        &:hover {
          background: none;
          color: $text-primary;
        }
      }

      /deep/ .el-checkbox__inner {
        width: 0.18rem;
        height: 0.18rem;
        border-width: 2px;

        &:hover {
          border-color: $tag-button-selected;;
        }

        &:after {
          height: 0.09rem;
          width: 0.04rem;
          top: 0;
          left: 0.04rem;
          border-width: 2px;
        }
      }

      /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
        height: 4px;
      }

      /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
      /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        border-color: $tag-button-selected;
        background-color: $tag-button-selected;
      }

      /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: $tag-button-selected;;
      }

      @include mobile {
        & /deep/ .el-checkbox__inner::after {
          height: 0.05rem;
          width: 0.04rem;
          left: 0.02rem;
        }
      }

      @include tablet-and-less {
        flex-direction: column;

        & > /deep/ .el-tree-node {
          margin-top: 0.1rem;
        }

        & /deep/ .el-tree-node {
          width: 100%;
          white-space: nowrap;

          &:first-child {
            margin-top: 0;
          }

          & > .el-tree-node__content {
            align-items: center;

            > .el-checkbox {
              font-size: 0.14rem;
            }
          }
        }
      }
    }

    .section-heading {
      margin-bottom: 0.26rem;
      color: $text-tertiary;
      font-weight: 600;
      font-size: 0.18rem;
      line-height: 0.32rem;
    }

    .topics {
      margin: -0.1rem;
      margin-bottom: 0.32rem;

      .topic-button {
        border-radius: 58px;
        margin: 0.1rem;
        padding: 0.11rem 0.21rem;
        border: none;
        color: $text-tertiary;
        background: $tag-button;
        font-size: 0.14rem;
        line-height: 0.25rem;

        &.selected {
          color: $color-white;
          background: $tag-button-selected;
          font-weight: 600;
        }
      }
    }

    .inquiry-message {
      /deep/ .el-textarea__inner {
        padding: 0.2rem;
        border: 1px solid $color-gray;
        font-family: 'Montserrat', sans-serif;

        &::placeholder {
          color: rgba($text-tertiary, 0.4);
          font-weight: 600;
        }
      }
    }
  }
</style>
